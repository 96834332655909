import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { multilanguage } from "redux-multilanguage";
import PropTypes from "prop-types";

const aboutbg = require("./../../assets/images/background/image-11.jpg");

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {
      strings: props.strings,
    };
  }

  render() {
    return (
      <>
        <Header />

        {/* <!-- Page Banner Section --> */}
        <section className="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div className="bottom-rotten-curve alternate"></div>

          <div className="auto-container">
            <h1>{this.props.strings["yonetim_kadrosu"]}</h1>
            <ul className="bread-crumb clearfix">
              <li>
                <Link to={"/#"}>{this.props.strings["anasayfa"]}</Link>
              </li>
              <li className="active text-lowercase">
                {this.props.strings["yonetim_kadrosu"]}
              </li>
            </ul>
          </div>
        </section>

        {/* <!-- Team Section --> */}
        <section className="team-section gray-bg">
          <div className="auto-container">
            <div className="sec-title text-center">
              <div className="sub-title">Özçiftay</div>
              <h2>{this.props.strings["yonetim_kadromuz"]}</h2>
            </div>

            {/* Yönetim Kurulu */}
            <div className="row justify-content-center">
              {/* <!-- Team Block One --> */}
              <div className="col-lg-4 col-md-6 team-block-one">
                <div className="inner-box">
                  <div className="image">
                    {/* <img
                      src={require("../../assets/images/resource/team-2.jpg")}
                      alt=""
                    /> */}
                  </div>
                  <div className="content text-center">
                    <div className="designation">
                      {this.props.strings["team_member_yonetim"]}
                    </div>
                    <h3>Önder Çiftci</h3>
                  </div>
                </div>
              </div>
            </div>

            {/* Diğer Yöneticiler */}
            <div className="row justify-content-center mt-5">
              {/* <!-- Team Block One --> */}
              <div className="col-lg-4 col-md-6 team-block-one">
                <div className="inner-box">
                  <div className="content text-center">
                    <div className="designation">
                      {this.props.strings["team_member_mudur"]}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Departman Yöneticileri */}
            <div className="row justify-content-center mt-4">
              {/* <!-- Team Block One --> */}
              <div className="col-lg-4 col-md-6 team-block-one">
                <div className="inner-box">
                  <div className="content text-center">
                    <div className="designation">
                      {this.props.strings["team_member_muhasabe"]}
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Team Block One --> */}
              <div className="col-lg-4 col-md-6 team-block-one">
                <div className="inner-box">
                  <div className="content text-center">
                    <div className="designation">
                      {this.props.strings["team_member_insan_kaynakları"]}
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Team Block One --> */}
              <div className="col-lg-4 col-md-6 team-block-one">
                <div className="inner-box">
                  <div className="content text-center">
                    <div className="designation">
                      {this.props.strings["team_member_satin"]}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}

Team.propTypes = {
  strings: PropTypes.object,
};
export default multilanguage(Team);
