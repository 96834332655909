import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Brand1 from "../element/brand1";
import propTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";
import ReactHtmlParser from "react-html-parser";
import ProgressBar from "react-bootstrap/ProgressBar";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import VideoPopup2 from "./../element/video-popup2";
import Testimonial1 from "../element/testimonial1";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const aboutbg = require("./../../assets/images/background/hakkimizda.jpg");
const counterbg1 = require("./../../assets/images/background/image-5.jpg");
const touchbg1 = require("./../../assets/images/background/image-8.jpg");

const mapbg2 = require("./../../assets/images/shape/pattern-1.png");

const percentage1 = 80;
const percentage2 = 90;
const percentage3 = 60;
const percentage4 = 85;

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      strings: props.strings,
    };
  }

  render() {
    return (
      <>
        <Header />

        <section class="page-banner">
          <div
            className="page-banner-bg"
            style={{ backgroundImage: "url(" + aboutbg + ")" }}
          ></div>
          <div class="bottom-rotten-curve alternate"></div>

          <div class="auto-container">
            <h1>{this.props.strings["hakkimizda"]}</h1>
            <ul class="bread-crumb clearfix">
              <li>
                <Link to={"/#"}>{this.props.strings["anasayfa"]}</Link>
              </li>
              <li class="active">{this.props.strings["hakkimizda"]}</li>
            </ul>
          </div>
        </section>

        {/* <!-- Portfolio Details --> */}
        <div class="portfolio-details">
          <div class="auto-container">
            <div class="sec-title">
              <div class="sub-title">{this.props.strings["hakkimizda"]}</div>
              <h2>{this.props.strings["biz_kimiz"]}</h2>
            </div>
            <div class="text">
              {ReactHtmlParser(this.props.strings["hakkimizda_icerik"])}
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="image-box">
                  <img src="/assets/images/gallery/list/hakkimizda/hakkimizda.jpg" alt="" />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="image-box">
                  <img src="/assets/images/gallery/resim4.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* History Section */}
        <section class="history-section">
          <div class="auto-container">
            <div class="sec-title text-center light">
              <div class="sub-title">{this.props.strings["history"]}</div>
              <h2>{this.props.strings["our_journey"]}</h2>
            </div>
            <Tab.Container defaultActiveKey="first">
              <Nav className="nav-tabs tab-btn-style-one"></Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first" className=" fadeInUp animated">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="image">
                        <img
                          src={"/assets/images/gallery/resim1.jpg"}
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="content pl-lg-4">
                        <div class="sec-title light">
                          <div class="sub-title">
                            {this.props.strings["kurucumuz"]}
                          </div>
                          {/* <h2>
                            Just Proved Our Selves <br />
                            For Great Works.
                          </h2> */}
                        </div>
                        <div class="text">
                          {ReactHtmlParser(
                            this.props.strings["kurucumuz_icerik"]
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </section>

        {/* <!-- Why Choose Us Section --> */}
        <section class="why-chooseus-section pt-0 mt-5">
          <div class="auto-container">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="image-wrapper">
                  <div class="image-one">
                    <img
                      src={"/assets/images/gallery/resim5.jpg"}
                      alt=""
                    />
                  </div>
                  <div class="image-two">
                    <img
                      src={"/assets/images/gallery/list/hakkimizda/hakkimizda280x179.jpg"}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="content-box ml-lg-3">
                  <div class="sec-title">
                    <div class="sub-title">{this.props.strings["why_us"]}</div>
                    {/* <h2>
                      Why Should You <br />
                      Choose Us ?
                    </h2> */}
                  </div>
                  <Tab.Container defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav nav-tabs tab-btn-style-one"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">
                          <span>{this.props.strings["vizyon_baslik"]}</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          <span>{this.props.strings["misyon_baslik"]}</span>
                        </Nav.Link>
                      </Nav.Item>
                     
                    </Nav>
                    <Tab.Content className="tabs-content">
                      <Tab.Pane eventKey="first" className="fadeInUp animated">
                        <div class="clearfix">
                         
                          <div class="text">
                            <p>
                              {ReactHtmlParser(
                                this.props.strings["misyon_icerik"]
                              )}{" "}
                            </p>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second" className="fadeInUp animated">
                        <div class="clearfix">
                      
                          <div class="text">
                            <p>
                              {ReactHtmlParser(
                                this.props.strings["vizyon_icerik"]
                              )}
                            </p>
                          </div>
                        </div>
                      </Tab.Pane>
                   
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </section>


        <VideoPopup2 />


        <Footer />
      </>
    );
  }
}
About.propTypes = {
  strings: propTypes.object,
};

export default multilanguage(About);
