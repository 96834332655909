import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Nav, Tab } from "react-bootstrap";
import Header from "../layout/header";
import Footer from "../layout/footer";
import HomeSlider1 from "./../element/home-slider1";
import ReactHtmlParser from "react-html-parser";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { multilanguage } from "redux-multilanguage";
import PropTypes from "prop-types";

import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VideoPopup1 from "./../element/video-popup1";
import PortfolioFilter1 from "./../element/portfolio-filter1";
import foto2 from "../../assets/images/resource/aboutsectionback.jpg";
import foto1 from "../../assets/images/resource/aboutsectionfront.jpg";

import hakkimizda1 from "../../assets/images/resource/ufak1.jpg";
import hakkimizda2 from "../../assets/images/resource/ufak2.jpg";
import hakkimizda3 from "../../assets/images/resource/ufak5.jpg";
import hakkimizda4 from "../../assets/images/resource/ufak4.jpg";

const wellcomebg1 = require("./../../assets/images/resource/sectiontwo.jpg");

const Index = ({ strings }) => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_q5001su",
        "template_m61yber",
        form.current,
        "--n2RwqKSVsQP0nKl"
      )
      .then((result) => {
        if (result.status === 200) {
          toast.success("İletişim Formunuz Başarıyla Gönderildi");
        } else {
          toast.error("İletişim Formunuz Gönderilemedi");
        }
      })
      .catch((err) => {
        toast.error("İletişim Formunuz Gönderilemedi");
      });
  };
  return (
    <>
      <Header />

      <HomeSlider1 />

      {/* <!-- Welcome Setion --> */}
      <section class="welcome-section pt-0">
        <div class="auto-container">
          <div class="wrapper-box">
            <div class="row m-0">
              {/* <!-- Welcome Block One --> */}
              <div
                class="welcome-block-one col-lg-4 wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1200ms"
              >
                <div class="inner-box">
                  <div class="icon-box">
                    <span class="flaticon-analysis"></span>
                  </div>
                  <h4 className="text-white mb-2">
                    {strings["index-3-box-1"]}
                  </h4>
                  <div class="text">{strings["index-3-box-1-aciklama"]}</div>
                </div>
              </div>
              {/* <!-- Welcome Block One --> */}
              <div
                class="welcome-block-one col-lg-4 wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1200ms"
              >
                <div
                  class="inner-box"
                  style={{ backgroundImage: "url(" + wellcomebg1 + ")" }}
                >
                  <div class="icon-box">
                    <span class="flaticon-tax"></span>
                  </div>
                  <h2> {strings["index-3-box-2"]} </h2>
                  <div class="text">{strings["index-3-box-2-aciklama"]}</div>
                </div>
              </div>
              {/* <!-- Welcome Block One --> */}
              <div
                class="welcome-block-one col-lg-4 wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1200ms"
              >
                <div class="inner-box">
                  <div class="icon-box">
                    <span class="flaticon-work-1"></span>
                  </div>
                  <h2> {strings["index-3-box-3"]} </h2>
                  <div class="text">{strings["index-3-box-3-aciklama"]}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- About Section --> */}
      <section class="about-section">
        <div class="auto-container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="image-wrapper">
                <div class="image-one">
                  <img src={foto2} alt="" />
                </div>
                <div
                  class="image-two wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div class="image-outer">
                    <img src={foto1} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="content-box">
                <div class="sec-title">
                  <div class="sub-title">{strings["About_Us"]}</div>
                  <h4>{strings["index-hakkimizda-baslik"]}</h4>
                  <div class="text">
                    {ReactHtmlParser(strings["Hakkimizda_Index"])}
                  </div>
                  <div class="text">
                    {" "}
                    {ReactHtmlParser(strings["Hakkimizda_Index_1"])}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Services Section --> */}
      <section class="services-section">
        <div class="auto-container">
          <div class="wrapper-box">
            <div class="left-column">
              <div class="services-content">
                <div class="sec-title light" style={{ marginBottom: "30px" }}>
                  <div class="sub-title">{strings["Our_Services"]}</div>
                  <h2>{strings["Neler_Yapiyoruz"]}</h2>
                </div>
                <div
                  class="icon-box wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div class="icon">
                    <img
                      src={require("../../assets/images/icons/icon.png")}
                      alt=""
                    />
                  </div>
                  <h2>{strings["faaliyet_alanlari_baslik_1"]}</h2>
                  <div class="text" style={{ paddingBottom: "5px" }}></div>
                </div>
                <div
                  class="icon-box wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div class="icon">
                    <img
                      src={require("../../assets/images/icons/icon.png")}
                      alt=""
                    />
                  </div>
                  <h2>{strings["faaliyet_alanlari_baslik_2"]}</h2>
                  <div class="text" style={{ paddingBottom: "5px" }}></div>
                </div>
                <div
                  class="icon-box wow fadeInUp fadeInUpsps"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div class="icon">
                    <img
                      src={require("../../assets/images/icons/icon.png")}
                      alt=""
                    />
                  </div>
                  <h2>{strings["faaliyet_alanlari_baslik_3"]}</h2>
                  <div class="text" style={{ paddingBottom: "5px" }}></div>
                </div>
                <div
                  class="icon-box wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div class="icon">
                    <img
                      src={require("../../assets/images/icons/icon.png")}
                      alt=""
                    />
                  </div>
                  <h2>{strings["faaliyet_alanlari_baslik_4"]}</h2>
                  <div class="text" style={{ paddingBottom: "5px" }}></div>
                </div>
                <div
                  class="icon-box wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div class="icon">
                    <img
                      src={require("../../assets/images/icons/icon.png")}
                      alt=""
                    />
                  </div>
                  <h2>{strings["faaliyet_alanlari_baslik_5"]}</h2>
                  <div class="text" style={{ paddingBottom: "5px" }}></div>
                </div>
                <div
                  class="icon-box wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div class="icon">
                    <img
                      src={require("../../assets/images/icons/icon.png")}
                      alt=""
                    />
                  </div>
                  <h2>{strings["faaliyet_alanlari_baslik_6"]}</h2>
                  <div class="text" style={{ paddingBottom: "5px" }}></div>
                </div>
                <div
                  class="icon-box wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div class="icon">
                    <img
                      src={require("../../assets/images/icons/icon.png")}
                      alt=""
                    />
                  </div>
                  <h2>{strings["faaliyet_alanlari_baslik_7"]}</h2>
                  <div class="text" style={{ paddingBottom: "5px" }}></div>
                </div>
                <div
                  class="icon-box wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div class="icon">
                    <img
                      src={require("../../assets/images/icons/icon.png")}
                      alt=""
                    />
                  </div>
                  <h2>{strings["faaliyet_alanlari_baslik_8"]}</h2>
                  <div class="text" style={{ paddingBottom: "5px" }}></div>
                </div>
                <div
                  class="icon-box wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div class="icon">
                    <img
                      src={require("../../assets/images/icons/icon.png")}
                      alt=""
                    />
                  </div>
                  <h2>{strings["faaliyet_alanlari_baslik_9"]}</h2>
                  <div class="text" style={{ paddingBottom: "5px" }}></div>
                </div>
              </div>
            </div>
            <div class="right-column sayac3" S>
              {/* <!-- Fact Counter --> */}
              <div class="fact-counter">
                <div class="border-box">
                  <div class="border_top"></div>
                  <div class="border_bottom"></div>
                  <div class="border_middile"></div>
                </div>
                <div class="row">
                  {/* <!--Column--> */}
                  <div class="column counter-column col-md-6">
                    <div
                      class="inner wow fadeInLeft"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="content">
                        <div class="icon">
                          <img
                            src={require("../../assets/images/icons/icon-6.png")}
                            alt=""
                          />
                        </div>
                        <div class="count-outer count-box">
                          <CountUp end={90} redraw={true}>
                            {({ countUpRef, start }) => (
                              <VisibilitySensor onChange={start} delayedCall>
                                <span class="count-text" ref={countUpRef} />
                              </VisibilitySensor>
                            )}
                          </CountUp>
                          <span>%</span>
                        </div>
                        <div class="counter-title">
                          {strings["index-faaliyet-image-title-1"]}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!--Column--> */}
                  <div class="column counter-column col-md-6">
                    <div
                      class="inner wow fadeInLeft"
                      data-wow-delay="300ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="content">
                        <div class="icon">
                          <img
                            src={require("../../assets/images/icons/icon-7.png")}
                            alt=""
                          />
                        </div>
                        <div class="count-outer count-box">
                          <CountUp end={71} redraw={true}>
                            {({ countUpRef, start }) => (
                              <VisibilitySensor onChange={start} delayedCall>
                                <span class="count-text" ref={countUpRef} />
                              </VisibilitySensor>
                            )}
                          </CountUp>
                          <span>%</span>
                        </div>
                        <div class="counter-title">
                          {strings["index-faaliyet-image-title-2"]}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!--Column--> */}
                  <div class="column counter-column col-md-6">
                    <div
                      class="inner wow fadeInLeft"
                      data-wow-delay="600ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="content">
                        <div class="icon">
                          <img
                            src={require("../../assets/images/icons/icon-8.png")}
                            alt=""
                          />
                        </div>
                        <div class="count-outer count-box">
                          <CountUp end={33} redraw={true}>
                            {({ countUpRef, start }) => (
                              <VisibilitySensor onChange={start} delayedCall>
                                <span class="count-text" ref={countUpRef} />
                              </VisibilitySensor>
                            )}
                          </CountUp>
                          <span>%</span>
                        </div>
                        <div class="counter-title">
                          {strings["index-faaliyet-image-title-3"]}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!--Column--> */}
                  <div class="column counter-column col-md-6 ">
                    <div
                      class="inner wow fadeInLeft"
                      data-wow-delay="900ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="content">
                        <div class="icon">
                          <img
                            src={require("../../assets/images/icons/icon-9.png")}
                            alt=""
                          />
                        </div>
                        <div class="count-outer count-box">
                          <CountUp end={42} redraw={true}>
                            {({ countUpRef, start }) => (
                              <VisibilitySensor onChange={start} delayedCall>
                                <span class="count-text" ref={countUpRef} />
                              </VisibilitySensor>
                            )}
                          </CountUp>
                          <span>%</span>
                        </div>
                        <div class="counter-title">
                          {strings["index-faaliyet-image-title-4"]}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!--Column--> */}
                  <div class="column counter-column col-md-6">
                    <div
                      class="inner wow fadeInLeft"
                      data-wow-delay="600ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="content">
                        <div class="icon">
                          <img
                            src={require("../../assets/images/icons/icon-10.png")}
                            alt=""
                          />
                        </div>
                        <div class="count-outer count-box">
                          <CountUp end={69} redraw={true}>
                            {({ countUpRef, start }) => (
                              <VisibilitySensor onChange={start} delayedCall>
                                <span class="count-text" ref={countUpRef} />
                              </VisibilitySensor>
                            )}
                          </CountUp>
                          <span>%</span>
                        </div>
                        <div class="counter-title">
                          {strings["index-faaliyet-image-title-5"]}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!--Column--> */}
                  <div class="column counter-column col-md-6">
                    <div
                      class="inner wow fadeInLeft"
                      data-wow-delay="900ms"
                      data-wow-duration="1500ms"
                    >
                      <div class="content">
                        <div class="icon">
                          <img
                            src={require("../../assets/images/icons/icon-11.png")}
                            alt=""
                          />
                        </div>
                        <div class="count-outer count-box">
                          <CountUp end={10} redraw={true}>
                            {({ countUpRef, start }) => (
                              <VisibilitySensor onChange={start} delayedCall>
                                <span class="count-text" ref={countUpRef} />
                              </VisibilitySensor>
                            )}
                          </CountUp>
                          <span>%</span>
                        </div>
                        <div class="counter-title">
                          {strings["index-faaliyet-image-title-6"]}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Gallery Section --> */}
      <PortfolioFilter1 />

      {/* <!-- Video Section --> */}
      <VideoPopup1 />

      {/* <!-- Why Choose Us Section --> */}
      <section class="why-chooseus-section">
        <div class="auto-container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="content-box">
                <div class="sec-title">
                  <div class="sub-title">
                    {strings["neden_bizi_secmelisiniz"]}
                  </div>
                  <h2>{strings["neden_bizi_secmelisiniz"]}</h2>
                </div>
                <Tab.Container defaultActiveKey="first">
                  <Nav
                    variant="pills"
                    className="nav nav-tabs tab-btn-style-one"
                  >
                    <Nav.Item>
                      <Nav.Link eventKey="first">
                        <span>{strings["vizyon_baslik"]}</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">
                        <span>{strings["misyon_baslik"]}</span>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content className="tabs-content">
                    <Tab.Pane eventKey="first" className="fadeInUp animated">
                      <div class="clearfix">
                        <div class="image">
                          <img src={hakkimizda1} alt="" />
                        </div>
                        <div class="text">
                          <p>{ReactHtmlParser(strings["misyon_icerik"])}</p>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second" className="fadeInUp animated">
                      <div class="clearfix">
                        <div class="image">
                          <img src={hakkimizda2} alt="" />
                        </div>
                        <div class="text">
                          <p>{ReactHtmlParser(strings["vizyon_icerik"])}</p>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="image-wrapper">
                <div class="image-one">
                  <img src={hakkimizda3} alt="" />
                </div>
                <div class="image-two">
                  <img src={hakkimizda4} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Contact Section --> */}
      <section class="contact-section">
        <div class="auto-container">
          <div class="row">
            <div class="col-lg-6">
              <div class="sec-title">
                <div class="sub-title">{strings["iletisim"]}</div>
                <h2>{strings["bize_yazin"]}</h2>
              </div>
              {/* <!-- Contact Form--> */}
              <div class="contact-form">
                <form ref={form} onSubmit={sendEmail}>
                  <div class="row clearfix">
                    <div class="col-md-12 form-group">
                      <label for="name">{strings["enter_your_name"]}</label>
                      <input
                        type="text"
                        name="ad_soyad"
                        id="name"
                        placeholder={strings["adiniz_soyadiniz"]}
                        required=""
                      />
                      <i class="fas fa-user"></i>
                    </div>

                    <div class="col-md-12 form-group">
                      <label for="email">{strings["enter_your_email"]}</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder={strings["eposta_adresiniz"]}
                        required=""
                      />
                      <i class="fas fa-envelope"></i>
                    </div>

                    <div class="col-md-12 form-group">
                      <label for="message">
                        {strings["enter_your_message"]}
                      </label>
                      <textarea
                        name="mesaj"
                        id="message"
                        placeholder={strings["mesajiniz"]}
                      ></textarea>
                      <i class="fas fa-edit"></i>
                    </div>

                    <div class="col-md-12 form-group">
                      <button class="theme-btn btn-style-one" type="submit">
                        <span class="btn-title">{strings["gonder"]}</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="sec-title">
                <div class="sub-title">{strings["iletisim"]}</div>
                <h2>{strings["bize_ulasin"]}</h2>
              </div>
              <div class="contact-info">
                <div class="border-box">
                  <div class="border_top"></div>
                  <div class="border_middile"></div>
                </div>
                <div class="row">
                  <div class="info-column col-md-6">
                    <div class="icon-box">
                      <div class="icon">
                        <span class="flaticon-email-6"></span>
                      </div>
                      <h3>{strings["eposta"]}</h3>
                      <ul>
                        <li>
                          <Link to={"/mailto:info@ozciftay.com.tr"}>
                            {strings["iletisim_merkez_eposta"]}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="info-column col-md-6">
                    <div class="icon-box">
                      <div class="icon">
                        <span class="flaticon-call-1"></span>
                      </div>
                      <h3>{strings["telefon"]}</h3>
                      <ul>
                        <li>
                          <Link to={"/tel:+90(312)4727677"}>
                            {strings["iletisim_merkez_telefon"]}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="info-column col-md-6">
                    <div class="icon-box">
                      <div class="icon">
                        <span class="flaticon-location"></span>
                      </div>
                      <h3>{strings["adres"]}</h3>
                      <ul>
                        <li>{strings["iletisim_merkez_adres1"]}</li>
                        <li>{strings["iletisim_merkez_adres2"]}</li>
                      </ul>
                    </div>
                  </div>
                  <div class="info-column col-md-6">
                    <div class="icon-box">
                      <div class="icon">
                        <span class="flaticon-worldwide"></span>
                      </div>
                      <h3>{strings["web_connect"]}</h3>
                      <ul>
                        <li>
                          <a target={"_blank"} href="https://ozciftay.com.tr">
                            {strings["web_adresleri1"]}
                          </a>
                        </li>
                        <li>
                          <a target={"_blank"} href="https://ciftas.com.tr">
                            {strings["web_adresleri2"]}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
Index.propTypes = {
  strings: PropTypes.object,
};

export default multilanguage(Index);
