import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import propTypes from "prop-types";
import { multilanguage } from "redux-multilanguage";
import makinaParkiTr from "../../data/makinaParkiTr.json";
import makinaParkiEn from "../../data/makinaParkiEn.json";

const ECompanyAccordion = ({ strings, currentLanguageCode }) => {
  const [accordionAll, setAccordion] = useState([]);

  useEffect(() => {
    // Local JSON dosyalarından veri yükleme (API yerine statik dosyalar kullanılıyor - 14.03.2024)
    const loadData = () => {
      try {
        const data =
          currentLanguageCode === "tr" ? makinaParkiTr : makinaParkiEn;

        /* API versiyonu (şu an kullanılmıyor)
        const fetchData = async () => {
          try {
            const response = await fetch(
              currentLanguageCode === "tr"
                ? "https://server.ozciftay.com.tr/makinaParkiTr"
                : "https://server.ozciftay.com.tr/makinaParkiEn"
            );
            if (!response.ok) {
              throw new Error("API request failed!");
            }
            const data = await response.json();
            // ... devamı
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        */

        const accordionItems = data.map((value, index) => (
          <AccordionItem key={index}>
            <AccordionItemHeading>
              <AccordionItemButton>{value.baslik}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{strings["marka"]}</th>
                    <th scope="col">{strings["tipi"]}</th>
                    <th scope="col">{strings["model"]}</th>
                  </tr>
                </thead>
                <tbody>
                  {value.icerik.map((value2, index2) => (
                    <tr key={index2}>
                      <th scope="row">{index2 + 1}</th>
                      <td>{value2.marka}</td>
                      <td>{value2.tipi}</td>
                      <td>{value2.model}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </AccordionItemPanel>
          </AccordionItem>
        ));
        setAccordion(accordionItems);
      } catch (error) {
        console.error("Error loading data:", error);
      }
    };

    loadData();
  }, [currentLanguageCode, strings]);

  return (
    <section className="faq-section">
      <div className="auto-container">
        <div className="row">
          <div className="col-lg-6">
            <Accordion preExpanded={[0]} allowZeroExpanded>
              {accordionAll.slice(accordionAll.length / 2)}
            </Accordion>
          </div>
          <div className="col-lg-6">
            <Accordion preExpanded={[0]} allowZeroExpanded>
              {accordionAll.slice(0, accordionAll.length / 2)}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

ECompanyAccordion.propTypes = {
  strings: propTypes.object,
  currentLanguageCode: propTypes.string,
};

export default multilanguage(ECompanyAccordion);
